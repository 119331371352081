const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView.vue') // lambda because the component's create() is async
    },
    {
        path: '/newhome',
        name: 'newhome',
        component: () => import('@/views/NewHomeView.vue') // lambda because the component's create() is async
    },
    {
        path: '/archive',
        name: 'archiveview',
        component: () => import('@/views/ArchiveView.vue') // lambda because the component's create() is async
    },
    {
        path: '/addaddress/attendee/:attendeeId/',
        name: 'addaddressview',
        component: () => import('@/views/AddOrEditAddress.vue') // lambda because the component's create() is async
    },
    {
        path: '/credit/:eventId',
        name: 'creditview',
        component: () => import('@/views/CreditView.vue') // lambda because the component's create() is async
    },
    {
        path: '/facilitatorguide/:eventId',
        name: 'facilitatorguideview',
        component: () => import('@/views/FacilitatorGuideView.vue') // lambda because the component's create() is async
    },
    {
        path: '/editaddress/:addressId/',
        name: 'editaddressview',
        component: () => import('@/views/AddOrEditAddress.vue') // lambda because the component's create() is async
    },
    {
        path: '/eventattendee/:eventId',
        name: 'eventattendeeview',
        component: () => import('@/views/EventAttendeeView.vue') // lambda because the component's create() is async
    },
    {
        path: '/eventexhibitors/:eventId',
        name: 'eventexhibitorsview',
        component: () => import('@/views/EventExternalEntitiesView.vue') // lambda because the component's create() is async
    },
    {
        path: '/eventmaterials/:eventId',
        name: 'eventmaterialsview',
        component: () => import('@/views/EventMaterialsView.vue') // lambda because the component's create() is async 
    },
    {
        path: '/eventpartners/:eventId',
        name: 'eventpartnersview',
        component: () => import('@/views/EventExternalEntitiesView.vue') // lambda because the component's create() is async 
    },
    {
        path: '/eventsponsors/:eventId',
        name: 'eventsponsorsview',
        component: () => import('@/views/EventExternalEntitiesView.vue') // lambda because the component's create() is async 
    },
    {
        path: '/eventvenues/:eventId',
        name: 'eventvenuesview',
        component: () => import('@/views/EventVenuesView.vue') // lambda because the component's create() is async
    },
    {
        path: '/externalentity/:externalEntityId',
        name: 'externalentityview',
        component: () => import('@/views/ExternalEntityView.vue') // lambda because the component's create() is async
    },
    {
        path: '/material/:sessionId',
        name: 'materialview',
        component: () => import('@/views/MaterialView.vue') // lambda because the component's create() is async
    },
    {
        path: '/myschedule/:eventId/:presentationMaterial?',
        name: 'myscheduleview',
        component: () => import('@/views/MyScheduleView.vue') // lambda because the component's create() is async
    },
    {
        path: '/notfound',
        name: 'notfoundview',
        component: () => import('@/views/NotFoundView.vue')
    },
    {
        path: '/payment/:eventId',
        name: 'paymentview',
        component: () => import('@/views/PaymentView.vue') // lambda because the component's create() is async
    },
    {
        path: '/presenter/:presenterId',
        name: 'presenterview',
        component: () => import('@/views/PresenterView.vue') // lambda because the component's create() is async
    },
    {
        path: '/presenters/:eventId',
        name: 'presentersview',
        component: () => import('@/views/PresentersView.vue') // lambda because the component's create() is async
    },
    {
        path: '/profile',
        name: 'myprofileview',
        component: () => import('@/views/MyProfileView.vue') // lambda because the component's create() is async
    },
    {
        path: '/profilephoto/:attendeeId',
        name: 'profilephotoview',
        component: () => import('@/views/ProfilePhotoView.vue') // lambda because the component's create() is async
    },
    {
        path: '/registration/:eventId',
        name: 'registrationview',
        component: () => import('@/views/RegistrationView.vue') // lambda because the component's create() is async 
    },
    {
        path: '/schedule/:eventId',
        name: 'scheduleview',
        component: () => import('@/views/ScheduleView.vue') // lambda because the component's create() is async
    },
    {
        path: '/welcome/:eventId',
        name: 'welcomeview',
        component: () => import('@/views/WelcomeView.vue') // lambda because the component's create() is async
    },
    /*{
        path: '/test',
        name: 'testview',
        component: () => import('@/views/TestView.vue') // lambda because the component's create() is async
    },*/
]

export default routes